import iconVk from '../images/platforms/vk-icon.webp';
import iconOk from '../images/platforms/ok-icon.webp';
import iconYg from '../images/platforms/yandex-games-icon.webp';
import iconCrazyGames from '../images/platforms/crazy-games-purple-icon.webp';
import iconGooglePlay from '../images/platforms/google-play-icon.webp';
import iconGM from '../images/platforms/gamemonetize-icon.webp';
import iconGD from '../images/platforms/gd.webp';
import iconGamePix from '../images/platforms/game-pix-icon.webp';
import iconPoki from '../images/platforms/poki-icon.webp';
import iconAppStore from '../images/platforms/apple-app-store-icon.webp';
import iconAppGallery from '../images/platforms/huawei-app-gallery-icon.webp';
import iconRuStore from '../images/platforms/ru-store-icon.webp';
import iconNashStore from '../images/platforms/nash-store-icon.webp';
import iconPlaydeck from '../images/platforms/telegram.webp';
import iconWGPlayground from '../images/platforms/wg_playground.webp';
import iconPikabu from '../images/platforms/pikabu.webp';

export const platformsConfig =  {
    yandexGames: {
        icon: iconYg,
        url: 'https://yandex.ru/games/app/',
    },
    crazyGames: {
        icon: iconCrazyGames,
        url: 'https://www.crazygames.com/game/',
    },
    ok: {
        icon: iconOk,
        url: 'https://ok.ru/game/',
    },
    vk: {
        icon: iconVk,
        url: 'https://vk.com/',
    },
    rustore: {
        icon: iconRuStore,
        url: 'https://www.rustore.ru/catalog/app/',
    },
    appGallery: {
        icon: iconAppGallery,
        url: 'https://appgallery.huawei.com/app/',
    },
    googlePlay: {
        icon: iconGooglePlay,
        url: 'https://play.google.com/store/apps/details?id=',
    },
    playdeck: {
        icon: iconPlaydeck,
        url: 'https://t.me/playdeckbot/game?startapp=',
        isDesktopAvailable: false,
    },
    gameMonetize: {
        icon: iconGM,
        url: 'https://gamemonetize.com/',
    },
    gamePix: {
        icon: iconGamePix,
        url: 'https://www.gamepix.com/play/',
    },
    wgPlayground: {
        icon: iconWGPlayground,
        url: 'https://www.wgplayground.com/game/',
    },
    poki: {
        icon: iconPoki,
        url: 'https://poki.com/ru/g/',
    },
    gameDistribution: {
        icon: iconGD,
        url: 'https://html5.gamedistribution.com/',
    },
    appStore: {
        icon: iconAppStore,
        url: 'https://apps.apple.com/by/app/',
    },
    nashStore: {
        icon: iconNashStore,
        url: 'https://store.nashstore.ru/store/',
    },
    pikabu: {
        icon: iconPikabu,
        url: 'https://games.pikabu.ru/game/',
    },
};