import chessCover from '../images/games/chess-cover.webp';
import checkersEnCoverWithEnTitle from '../images/games/checkers-en-cover-with-en-title.webp';
import checkersRuCoverWithEnTitle from '../images/games/checkers-ru-cover-with-en-title.webp';
import poddavkiEnCover from '../images/games/poddavki-en.webp';
import petsAndPaintsCover from '../images/games/pets-and-paints-cover.en.webp';
import saperDuelCover from '../images/games/saper-cover-purpur.en.webp';
import marketplaceCover from '../images/games/marketplace-cover.webp';
import retroRacerTurboCover from '../images/games/retro-racer-turbo-cover.webp';

import { gamesCommon } from './gamesCommon';

export const games = {
    chess: {
        ...gamesCommon.chess,
        logo: chessCover,
    },
    checkersEn: {
        ...gamesCommon.checkersEn,
        logo: checkersEnCoverWithEnTitle,
    },
    checkersRu: {
        ...gamesCommon.checkersRu,
        logo: checkersRuCoverWithEnTitle,
    },
    checkersPoddavki: {
        ...gamesCommon.checkersPoddavki,
        logo: poddavkiEnCover,
    },
    saper: {
        ...gamesCommon.saper,
        logo: saperDuelCover,
    },
    petsAndPaints: {
        ...gamesCommon.petsAndPaints,
        logo: petsAndPaintsCover,
    },
    marketplace: {
        ...gamesCommon.marketplace,
        logo: marketplaceCover,
    },
    retroRacerTurbo: {
        ...gamesCommon.retroRacerTurbo,
        logo: retroRacerTurboCover,
    },
}